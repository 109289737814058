.custom-video-player {
    /* position: relative; */
    width: 100%;
    /* max-width: 800px; */
    margin: 0 auto;
    height: 100%;

  }
  
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    position: absolute;
    bottom: 2%;
    width: 100%;
    left: 0px;
    gap:30px;
  }
  
 #video button {
    background-color: #5e35b1;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-right: 10px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    width:100px;
    height: 100px;
    /* font-size: 50; */
  }
  
 #video button:hover {
    background-color: #5e35b1;
  }
  
  input[type="range"] {
    width: 100px;
    margin: 0 10px;
  }
  
  /* Customize the appearance of the range input */
  input[type="range"] {
    -webkit-appearance: none;
    width: 100%;
    height: 8px;
    border-radius: 5px;
    background: #ccc;
    outline: none;
  }
  
  /* Customize the appearance of the thumb */
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #5e35b1;
    cursor: pointer;
  }
  
  /* Style the thumb on hover */
  input[type="range"]::-webkit-slider-thumb:hover {
    background: #45a049;
  }
  .seak_class{
    /* position: absolute; */
    width: 80%;
    /* height: 60px; */
    /* left:1%;
    bottom:10%; */
    /* background-color: bisque; */
  }
  .seak_vol{
    /* position: absolute; */
    width: 10%;
    height: 60px;
    /* right:2%;
    bottom:10%; */
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .history_icon{
    position: absolute;
    left:20%;
    top: 50%;
    transform: translateY(-50%);
    cursor:pointer;
    /* left: 50%;
    transform: translate(-50%, -50%); */
  }
  .forward_icon{
    position: absolute;
    right:20%;
    top: 50%;
    transform: translateY(-50%);
    cursor:pointer;
  }